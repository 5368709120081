import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
    Grid,
    Paper,
    FormControlLabel,
    Switch,
    Box,
    Button,
    Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { apiRequest } from "../../../api/ApiCall";
import { v4 as uuidv4 } from 'uuid';
import { useUtilityContext } from '../../../utility-provider';
import useSound from 'use-sound';
import beep_2 from '../../../sound/beep_2.mp3';
import move_2 from '../../../sound/move_2.mp3';
import decision_2 from '../../../sound/decision_2.mp3';
import decision_31 from '../../../sound/decision_31.mp3';
import decision_38 from '../../../sound/decision_38.mp3';
import decision_48 from '../../../sound/decision_48.mp3';
import alarm_1 from '../../../sound/alarm_1.mp3';
import ConfirmDialog from '../../common/ConfirmDialog';

export interface resultProps {
    props:{
    }
}

interface State {
    planAreaId: string;
    referrer: string;
    measurementTime: number;
    measurementType: string;
}

const defaultCountList = {
    'car_total': 0,
    'car_front_standard': 0,
    'car_front_light': 0,
    'car_front_large': 0,
    'car_side_a_standard': 0,
    'car_side_a_light': 0,
    'car_side_a_large': 0,
    'car_side_b_standard': 0,
    'car_side_b_light': 0,
    'car_side_b_large': 0,
    'car_back_standard': 0,
    'car_back_light': 0,
    'car_back_large': 0,
    'people_total': 0,
    'people_5m_in_male_65over': 0,
    'people_5m_in_female_65over': 0,
    'people_5m_in_male_other': 0,
    'people_5m_in_female_other': 0,
    'people_5m_in_student': 0,
    'people_5m_in_bicycle': 0,
    'people_5m_out_male_65over': 0,
    'people_5m_out_female_65over': 0,
    'people_5m_out_male_other': 0,
    'people_5m_out_female_other': 0,
    'people_5m_out_student': 0,
    'people_5m_out_bicycle': 0,
};

const carCount = () => {
    return [
        { 'label': '前面', 'key': 'front', 'standard': 'car_front_standard', 'light': 'car_front_light', 'large': 'car_front_large' },
        { 'label': '側A', 'key': 'side_a', 'standard': 'car_side_a_standard', 'light': 'car_side_a_light', 'large': 'car_side_a_large' },
        { 'label': '側B', 'key': 'side_b', 'standard': 'car_side_b_standard', 'light': 'car_side_b_light', 'large': 'car_side_b_large' },
        { 'label': '裏道', 'key': 'back', 'standard': 'car_back_standard', 'light': 'car_back_light', 'large': 'car_back_large' },
    ]
}

const peopleCount = () => {
    return [
        { 'label': '5M内', 'male': 'people_5m_in_male_65over', 'female': 'people_5m_in_female_65over', 's_b': 'people_5m_in_student' },
        { 'label': '5M内', 'male': 'people_5m_in_male_other', 'female': 'people_5m_in_female_other', 's_b': 'people_5m_in_bicycle' },
        { 'label': '5M外', 'male': 'people_5m_out_male_65over', 'female': 'people_5m_out_female_65over', 's_b': 'people_5m_out_student' },
        { 'label': '5M外', 'male': 'people_5m_out_male_other', 'female': 'people_5m_out_female_other', 's_b': 'people_5m_out_bicycle' },
    ]
}

const TrafficVolume:React.FC<resultProps> = ({props}) => {
    const utilityCtx = useUtilityContext();
    const location = useLocation();
    const {
        planAreaId,
        referrer,
        measurementTime,
        measurementType
    } = location.state as State;
    const [countList, setCountList] = React.useState(defaultCountList);
    const [checked, setChecked] = React.useState(true);
    const [counter, setCounter] = React.useState(false);
    const [counterStart, setCounterStart] = React.useState(false)
    const [countTimer, setCountTimer] = React.useState<number>(measurementTime * 60)
    const [countStart, setCountStart] = React.useState(false)
    const [trafficJamTime, setTrafficJamTime] = React.useState<number>(0)
    const [trafficJamStart, setTrafficJamStart] = React.useState(false)
    const [sideTrafficJamTime, setSideTrafficJamTime] = React.useState<number>(0)
    const [sideTrafficJamStart, setSideTrafficJamStart] = React.useState(false)
    const [countDate, setCountDate] = React.useState<Date>()
    const [sound_beep_2] = useSound(beep_2);
    const [sound_move_2] = useSound(move_2);
    const [sound_decision_2] = useSound(decision_2);
    const [sound_decision_31] = useSound(decision_31);
    const [sound_decision_38] = useSound(decision_38);
    const [sound_decision_48] = useSound(decision_48);
    const [sound_alarm_1] = useSound(alarm_1);
    const [open, setOpen] = React.useState<boolean>(false);
    const [confirmMode, setConfirmMode] = React.useState<string>("");
    const [confirmBody, setConfirmBody] = React.useState<string>("");
    const [confirmCancelButton, setConfirmCancelButton] = React.useState<string>("");
    const [confirmOkButton, setConfirmOkButton] = React.useState<string>("");
    const [confirmCancelRegistButton, setConfirmCancelRegistButton] = React.useState<string>("");
    const backRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const copyData = JSON.parse(JSON.stringify(defaultCountList))
        setCountList(copyData)
    }, [measurementType])
    const objReceptionBook = useAppSelector((state) => state.receptionBook.select);

    const CountItem = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        border: '2px solid #F00',
        display: 'grid',
        placeItems: 'center',
        width: '100%',
      }));
    const ItemLabel = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        textAlign: 'center',
        display: 'grid',
        placeItems: 'center',
        boxShadow: 'none',
      }));
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        border: '2px solid #ffa500',
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        color: '#FFF',
        backgroundColor: '#ffa500',
      }));


    const setCount = (key: string) => {
        if (counterStart && countList) {
            const tmp:any[] = [];
            tmp.push(countList)
            if (checked) {
                // +
                tmp[0][key] = tmp[0][key] + 1
                if (key.indexOf('car') >= 0) {
                    tmp[0]['car_total'] = tmp[0]['car_total'] + 1
                } else {
                    tmp[0]['people_total'] = tmp[0]['people_total'] + 1
                }

                if (key.includes('front')) {
                    sound_move_2(); 
                } else if (key.includes('side')) {
                    sound_decision_48();
                } else if (key.includes('back')) {
                    sound_decision_48();
                } else if (key.includes('5m_in')) {
                    sound_decision_2();
                } else if (key.includes('5m_out')) {
                    sound_decision_38();
                }
            } else {
                // -
                if (tmp[0][key] > 0) {
                    tmp[0][key] = tmp[0][key] - 1
                    if (key.indexOf('car') >= 0) {
                        tmp[0]['car_total'] = tmp[0]['car_total'] - 1
                    } else {
                        tmp[0]['people_total'] = tmp[0]['people_total'] - 1
                    }
                }

                sound_beep_2();
            }

            setCounter(!counter)
            setCountList(tmp[0])
        }

        return
    };

    const getCount = (key: string) => {
        const tmp:any[] = [];
        tmp.push(countList)
        return tmp[0][key]
    }

    const carDisplay = () => {
        return measurementType === "0" 
        ? { margin: "0 10px", display: 'block' } 
        : measurementType === "1"  ? { margin: "15px 10px", display: 'block' } :{ display: 'none' }
    }

    const peopleDisplay = () => {
        return measurementType === "0" 
        ? { margin: "0 10px", display: 'block' } 
        : measurementType === "2"  ? { margin: "30px 10px", display: 'block' } :{ display: 'none' }
    }
    
    const setMargin = () => {
        return measurementType === "0" ? { marginBottom: "0" } : { margin: "30px 0" }
    }

    // 測定開始
    const checkCounterStart = () => {
        if (counterStart === false) {
            setCounterStart(true)
            setCountStart(true)
        }
    }
    
    // -+の切替
    function handleChange() {
        setChecked(!checked)
    }

    const onRegist = () => {
        let count_date = ""
        if (countDate) {
            count_date = countDate.getUTCFullYear() + "-" +
            (countDate.getUTCMonth() + 1).toString().padStart(2, "0") + "-" +
            countDate.getUTCDate().toString().padStart(2, "0") + " " +
            (countDate.getUTCHours() + 9).toString().padStart(2, "0") + ":" +
            countDate.getUTCMinutes().toString().padStart(2, "0") + ":" +
            countDate.getUTCSeconds().toString().padStart(2, "0") + "." +
            countDate.getUTCMilliseconds()
        }

        const params = {
            mode: "postRequest",
            endPoint: "/location/TrafficVolume/v1/regist",
            query: {
                uuid: uuidv4(),
                plan_area_id: planAreaId.toString(),
                count_date: count_date.toString(),
                count_time: measurementTime.toString(),
                front_traffic_jam_time: (Math.round((trafficJamTime / 60) * 100) / 100).toString(),
                side_a_traffic_jam_time: (Math.round((sideTrafficJamTime / 60) * 100) / 100).toString(),
                count_type: measurementType.toString(),
                car_front_standard: countList.car_front_standard.toString(),
                car_front_light: countList.car_front_light.toString(),
                car_front_large: countList.car_front_large.toString(),
                car_side_a_standard: countList.car_side_a_standard.toString(),
                car_side_a_light: countList.car_side_a_light.toString(),
                car_side_a_large: countList.car_side_a_large.toString(),
                car_side_b_standard: countList.car_side_b_standard.toString(),
                car_side_b_light: countList.car_side_b_light.toString(),
                car_side_b_large: countList.car_side_b_large.toString(),
                car_back_standard: countList.car_back_standard.toString(),
                car_back_light: countList.car_back_light.toString(),
                car_back_large: countList.car_back_large.toString(),
                people_5m_in_male_65over: countList.people_5m_in_male_65over.toString(),
                people_5m_in_female_65over: countList.people_5m_in_female_65over.toString(),
                people_5m_in_male_other: countList.people_5m_in_male_other.toString(),
                people_5m_in_female_other: countList.people_5m_in_female_other.toString(),
                people_5m_in_student: countList.people_5m_in_student.toString(),
                people_5m_in_bicycle: countList.people_5m_in_bicycle.toString(),
                people_5m_out_male_65over: countList.people_5m_out_male_65over.toString(),
                people_5m_out_female_65over: countList.people_5m_out_female_65over.toString(),
                people_5m_out_male_other: countList.people_5m_out_male_other.toString(),
                people_5m_out_female_other: countList.people_5m_out_female_other.toString(),
                people_5m_out_student: countList.people_5m_out_student.toString(),
                people_5m_out_bicycle: countList.people_5m_out_bicycle.toString(),
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
          }
          (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
                }
                finishRegist()
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
                }
                errorRegist()
            }
          })();
    }

    // 計測カウントダウン
    React.useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (countStart) {
                if (countTimer === 0) {
                    clearInterval(countDownInterval)
                    showRegistConfirm()
                } else if (countTimer && countTimer > 0) {
                    setCountTimer(countTimer - 1)
                }
            } else {
                clearInterval(countDownInterval)
            }
        }, 1000)
        return () => {
            clearInterval(countDownInterval)
        }
    }, [countTimer, countStart])

    // 前面渋滞カウントアップ
    React.useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (trafficJamStart) {
                setTrafficJamTime(trafficJamTime + 1)
            }
        }, 1000)
        return () => {
            clearInterval(countDownInterval)
        }
    }, [trafficJamTime, trafficJamStart])

    const trafficJam = () => {
        if (counterStart) {
            sound_decision_31();
            setTrafficJamStart(!trafficJamStart)
        }
    }

    // 側A渋滞カウントアップ
    React.useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (sideTrafficJamStart) {
                setSideTrafficJamTime(sideTrafficJamTime + 1)
            }
        }, 1000)
        return () => {
            clearInterval(countDownInterval)
        }
    }, [sideTrafficJamTime, sideTrafficJamStart])

    const sideTrafficJam = () => {
        if (counterStart) {
            sound_decision_31();
            setSideTrafficJamStart(!sideTrafficJamStart)
        }
    }

    React.useEffect(() => {
        let date = new Date();
        setCountDate(date)
    }, [])

    const displayDate = () => {
        let date = ""
        if (countDate) {
            date = countDate.getFullYear().toString().substring(2, 4)
            + "/" + (countDate.getMonth() + 1).toString().padStart(2, "0")
            + "/" + (countDate.getDate()).toString().padStart(2, "0");    
        }

        return date
    }

    const setLabel = (indexLabel: string, index: number, position: string) => {
        let label = ""
        switch(position) {
            case 'left':
                label = indexLabel + " 65上男"
                if ((index % 2) === 1) {
                    label = indexLabel + " その他男"
                }
                break;
            case 'center':
                label = indexLabel + " 65上女"
                if ((index % 2) === 1) {
                    label = indexLabel + " その他女"
                }
                break;
            case 'right':
                label = indexLabel + " 中高生"
                if ((index % 2) === 1) {
                    label = indexLabel + " 自転車"
                }
                break;
        }

        return label
    }

    const counterPadding = (target: string) => {
        let padding = ''
        switch(target) {
            case 'box':
                padding = '0'
                break;
            case 'label':
                padding = measurementType === '0' ? '2px' : '12px'
                break;
        }

        return padding
    }

    const displayTrafficJamTime = (time : any) => {
        const minute = Math.floor(time / 60)
        return minute + ':' + (time - (minute * 60)).toString().padStart(2, "0")
    }

    const settingBody = (type: string) => {
        let body = ""
        switch (type) {
            case  'back':
            case  'regist':
                body = "現在の計測結果\n";
                if (measurementType === '0') {
                    body += "車："+countList.car_total+"\n人："+countList.people_total
                } else if (measurementType === '1') {
                    body += "車："+countList.car_total
                } else if (measurementType === '2') {
                    body += "人："+countList.people_total
                }
                break;
            case 'cancelConfirm':
                body = "計測結果を保存せずに\n終了してもよろしいですか";
                break;
            case 'finishRegist':
                body = "保存が完了しました";
                break;
            case 'errorRegist':
                body = "保存に失敗しました";
                break;
        }

        setConfirmBody(body)
    }

    const clickBack = () => {
        settingBody('back')
        setConfirmCancelButton("キャンセル")
        setConfirmOkButton("")
        setConfirmCancelRegistButton("計測中止")
        setConfirmMode("back")
        setCountStart(false)
        setTrafficJamStart(false)
        setSideTrafficJamStart(false)
        setOpen(true)
    }

    const showRegistConfirm = () => {
        sound_alarm_1()
        settingBody('regist')
        setConfirmCancelButton("キャンセル")
        setConfirmOkButton("保存")
        setConfirmCancelRegistButton("")
        setConfirmMode("regist")
        setCountStart(false)
        setTrafficJamStart(false)
        setSideTrafficJamStart(false)
        setOpen(true)
    }

    const showCancelConfirm = () => {
        settingBody('cancelConfirm')
        setConfirmCancelButton("いいえ")
        setConfirmOkButton("")
        setConfirmCancelRegistButton("はい")
        setConfirmMode("cancelConfirm")
    }

    const finishRegist = () => {
        settingBody('finishRegist')
        setConfirmCancelButton("")
        setConfirmOkButton("閉じる")
        setConfirmCancelRegistButton("")
        setConfirmMode("finishRegist")
        setOpen(true)
    }

    const errorRegist = () => {
        settingBody('errorRegist')
        setConfirmCancelButton("")
        setConfirmOkButton("閉じる")
        setConfirmCancelRegistButton("")
        setConfirmMode("errorRegist")
        setOpen(true)
    }

    const confirmCancel = () => {
        if (confirmMode) {
            switch (confirmMode) {
                case 'back':
                    setCountStart(true)
                    setOpen(false)
                    break;
                case 'regist':
                    showCancelConfirm()
                    break;
                case 'cancelConfirm':
                    showRegistConfirm()
                    break;
            }
        }
    }

    const confirmRegist = () => {
        setOpen(false)
        switch (confirmMode) {
            case 'regist':
                onRegist()
                break;
            case 'cancelConfirm':
            case 'finishRegist':
            case 'errorRegist':
                confirmCancelRegist()
                break;
        }
    }

    const confirmCancelRegist = () => {
        setOpen(false)
        if (backRef) {
            backRef.current?.click();
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <ConfirmDialog 
                props={{
                    open:open,
                    handleModal:setOpen,
                    onCancel:confirmCancel,
                    onExecution:confirmRegist,
                    onCancelRegist:confirmCancelRegist,
                    mode:confirmMode,
                    body:confirmBody,
                    confirmCancel:confirmCancelButton,
                    confirmOk:confirmOkButton,
                    confirmCancelRegist:confirmCancelRegistButton,
                }}
            />
            <Box sx={{ margin: '10px' }}>
                <Grid container>
                    <Grid item xs={1}>
                        <Typography
                            sx={{margin: '0'}}
                            onClick={clickBack}
                        >≪</Typography>
                        <Link to={"/TrafficVolume/select?planAreaId="+objReceptionBook.plan_area_id+"&referrer="+referrer}><div ref={backRef}></div></Link>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{margin: '0'}}>{displayDate()}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Item onClick={checkCounterStart}>
                            {counterStart === false
                            ? '計測開始'
                            : String(Math.floor(countTimer / 60).toString().padStart(2, "0")) + '：' + String(countTimer % 60).toString().padStart(2, "0")}
                        </Item>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign: 'right'}}>-<FormControlLabel
                            control={<Switch checked={checked} onChange={handleChange} />}
                            label="+"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ margin: "10px" }}>
                <Box sx={{ display: 'flex', gap: '10px', justifyContent: measurementType === '2' ? 'end' : 'space-around'}}>
                    <CountItem onClick={trafficJam}  sx={{
                        color: trafficJamStart === false ? 'black' : 'white',
                        backgroundColor: trafficJamStart === false ? '#FFF' : '#e9546b', width: '74%',
                        display: measurementType === '2' ? 'none' : 'block'
                    }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                            <Box>前面<br />渋滞</Box>
                            <Box sx={{margin: '0', padding: '10px 0'}}>{displayTrafficJamTime(trafficJamTime)}</Box>
                        </Box>
                    </CountItem>
                    <CountItem onClick={sideTrafficJam} sx={{
                        color: sideTrafficJamStart === false ? 'black' : 'white',
                        backgroundColor: sideTrafficJamStart === false ? '#FFF' : '#e9546b', width: '74%',
                        display: measurementType === '2' ? 'none' : 'block'
                    }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                            <Box>側A<br />渋滞</Box>
                            <Box sx={{margin: '0', padding: '10px 0'}}>{displayTrafficJamTime(sideTrafficJamTime)}</Box>
                        </Box>
                    </CountItem>
                    <CountItem sx={{ border: '2px solid #808080', color: 'black', backgroundColor: '#FFF', width: measurementType === '2' ? '40%' : '100%'}}>
                        <Box sx={{ width: '100%', display: 'flex' }}>
                            <Box sx={{margin: '0', padding: '10px'}}>合計</Box>
                            <Box>
                                <Box sx={{margin: '0', padding: measurementType === '1' ? '10px' : '0', display: measurementType === '2' ? 'none' : 'block' }}>車：{countList.car_total}</Box>
                                <Box sx={{margin: '0', padding: measurementType === '2' ? '10px' : '0', display: measurementType === '1' ? 'none' : 'block' }}>人：{countList.people_total}</Box>
                            </Box>
                        </Box>
                    </CountItem>
                </Box>
            </Box>
            {/* ボタンエリア */}
            <Box sx={carDisplay()}>
                {carCount().map((row: any) => (
                    <Box sx={setMargin()} key={row.label}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box onClick={() => setCount(row.standard)} sx={{ border: '2px solid #00F', borderRadius: '0', padding: counterPadding('box') }}>
                                    <ItemLabel sx={{ borderRadius: '0', fontSize: '1.2rem', backgroundColor: '#87cefa', padding: counterPadding('label') }}>{getCount(row.standard)}</ItemLabel>
                                    <ItemLabel sx={{ borderRadius: '0', fontSize: '0.75rem', backgroundColor: '#87cefa' }}>{row.label+" 普通"}</ItemLabel>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box onClick={() => setCount(row.light)} sx={{ border: '2px solid #00F', borderRadius: '0', padding: counterPadding('box') }}>
                                    <ItemLabel sx={{ borderRadius: '0', fontSize: '1.2rem', backgroundColor: '#87cefa', padding: counterPadding('label') }}>{getCount(row.light)}</ItemLabel>
                                    <ItemLabel sx={{ borderRadius: '0', fontSize: '0.75rem', backgroundColor: '#87cefa' }}>{row.label+" 軽"}</ItemLabel>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box onClick={() => setCount(row.large)} sx={{ border: '2px solid #00F', borderRadius: '0', padding: counterPadding('box') }}>
                                    <ItemLabel sx={{ borderRadius: '0', fontSize: '1.2rem', backgroundColor: '#87cefa', padding: counterPadding('label') }}>{getCount(row.large)}</ItemLabel>
                                    <ItemLabel sx={{ borderRadius: '0', fontSize: '0.75rem', backgroundColor: '#87cefa' }}>{row.label+" 大型"}</ItemLabel>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>
            {/* ボタンエリア年齢別 */}
            <Box sx={peopleDisplay()}>
                {peopleCount().map((row: any, index: number) => (
                    <Box sx={setMargin()} key={index}>
                        <Box>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Box onClick={() => setCount(row.male)} sx={{ border: row.label === '5M内' ? '2px solid #006400' : '2px solid #2e8b57', borderRadius: '0', padding: counterPadding('box') }}>
                                        <ItemLabel sx={{ borderRadius: '0', fontSize: '1.2rem', backgroundColor: row.label === '5M内' ? '#3cb371' : '#90ee90', padding: counterPadding('label') }}>{getCount(row.male)}</ItemLabel>
                                        <ItemLabel sx={{ borderRadius: '0', fontSize: '0.75rem', backgroundColor: row.label === '5M内' ? '#3cb371' : '#90ee90' }}>{setLabel(row.label, index, 'left')}</ItemLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box onClick={() => setCount(row.female)} sx={{ border: row.label === '5M内' ? '2px solid #006400' : '2px solid #2e8b57', borderRadius: '0', padding: counterPadding('box') }}>
                                        <ItemLabel sx={{ borderRadius: '0', fontSize: '1.2rem', backgroundColor: row.label === '5M内' ? '#3cb371' : '#90ee90', padding: counterPadding('label') }}>{getCount(row.female)}</ItemLabel>
                                        <ItemLabel sx={{ borderRadius: '0', fontSize: '0.75rem', backgroundColor: row.label === '5M内' ? '#3cb371' : '#90ee90' }}>{setLabel(row.label, index, 'center')}</ItemLabel>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box onClick={() => setCount(row.s_b)} sx={{ border: row.label === '5M内' ? '2px solid #006400' : '2px solid #2e8b57', borderRadius: '0', padding: counterPadding('box') }}>
                                        <ItemLabel sx={{ borderRadius: '0', fontSize: '1.2rem', backgroundColor: row.label === '5M内' ? '#3cb371' : '#90ee90', padding: counterPadding('label') }}>{getCount(row.s_b)}</ItemLabel>
                                        <ItemLabel sx={{ borderRadius: '0', fontSize: '0.75rem', backgroundColor: row.label === '5M内' ? '#3cb371' : '#90ee90' }}>{setLabel(row.label, index, 'right')}</ItemLabel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
        
}

export default TrafficVolume;
