import { createSlice } from '@reduxjs/toolkit';
import { initReceptionBookObj } from '../app/type';

//Sliceの作成
export const receptionBookSlice = createSlice({
    name: 'conflict',
    initialState: {
        list: [],
        select: initReceptionBookObj
    },

    //toolKitを利用した値変更処理
    reducers: {
        setReceptionBookList: (state, action) => {
            state.list = action.payload;
        },
        setReceptionBook: (state, action) => {
            state.select = action.payload;
        },
    }
});

export const {
    setReceptionBookList,
    setReceptionBook,
} = receptionBookSlice.actions;
export default receptionBookSlice.reducer;