import axios from "axios";
import { getFirebaseAuthToken } from "../utility/firebase"

// GETリクエスト
export const getRequest = async (
  url: string
  // params: any,
  // isLoading: boolean = true
) => {
  // try {
  const response = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + (await getFirebaseAuthToken(false)),
    },
    // params,
  });

  return response.data.dataList;
};

// POSTリクエスト
export const postRequest = async (
  url: string,
  params: any,
  isGraphQL: boolean = true,
  isAuthed: boolean = true,
  isAuthUpdate: boolean = true,
) => {
  let headers = {};
  if (isAuthed) {
    if (isGraphQL) {
      headers = { "Content-Type": "application/json", myHeader: "bb",
        Authorization: "Bearer " + (await getFirebaseAuthToken(isAuthUpdate)), };
    } else {
      headers = {
        Authorization: "Bearer " + (await getFirebaseAuthToken(isAuthUpdate)),
        "Application-Name": "ofc-dashboard",
      };
    }
  }
  try {
    const response = await axios.post(url, params, {
      headers: headers
    });

    return response;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
