import { FullMetadata, getMetadata, listAll, list, getDownloadURL, deleteObject, getBlob, ref } from 'firebase/storage';
import { storage } from './firebase';


const listPageMax = 5

/**
 * 該当ディレクトリ取得
 * @params path
 */
export const getObjectFroFirebase = async (path: string) => {
    const listRef = ref(storage, path);
  
    const response = await listAll(listRef)
    const pdfList: FullMetadata[] = [];
    for(let i = 0;i < response.items.length; i++){
      const metaData = await getMetadata(response.items[i]);
      pdfList.push(metaData);
    }

    return pdfList;
}

/**
 * 該当ディレクトリ取得
 * 1ページ：maxResults件
 * @params path
 * @params currentPage
 * @params pageToken
 * @params tokenArray
 */
export const getListPaginateFroFirebase = async (path: string, currentPage: number, pageToken: string) => {
    let page = currentPage
    const listRef = ref(storage, path);
    const pdfList: FullMetadata[] = [];
    const tmpToken: any = []
    let nextPageToken: any = []
    if (pageToken === "") {
        const listRef = ref(storage, path);
        const firstPage = await list(listRef, { maxResults: listPageMax });
        for(let i = 0;i < firstPage.items.length; i++){
            const metaData = await getMetadata(firstPage.items[i]);
            pdfList.push(metaData);
        }
        if (firstPage.nextPageToken) {
            page = page + 1
            tmpToken.push({
                page: page,
                token: firstPage.nextPageToken
            })
            await getPageToken(listRef, page, firstPage.nextPageToken, tmpToken)
            .then((result: []) => {
                nextPageToken.push(result)
            })
        }
    }

    return {
        item: pdfList,
        nextPageToken: nextPageToken[0]
    }
}

/**
 * 該当ページ取得
 * @params path
 * @params currentPage
 * @params pageToken
 * @params tokenArray
 */
export const getTargetPage = async (path: string, currentPage: number, pageToken: string, tokenArray: any) => {
    let page = currentPage
    const listRef = ref(storage, path);
    const pdfList: FullMetadata[] = [];
    const secondPage = await list(listRef, {
        maxResults: listPageMax,
        pageToken: pageToken,
    });
    for(let i = 0;i < secondPage.items.length; i++){
        const metaData = await getMetadata(secondPage.items[i]);
        pdfList.push(metaData);
    }

    return {
        item: pdfList,
        nextPageToken: tokenArray
    }
}

// firebase storageページトークン取得
const getPageToken = async (listRef: any, page: number, pageToken: string, pageTokenArray: any) => {
    const nextPage = await list(listRef, {
        maxResults: listPageMax,
        pageToken: pageToken,
    });

    if (nextPage.nextPageToken) {
        page = page + 1
        pageTokenArray.push({
            page: page,
            token: nextPage.nextPageToken
        })
        
        await getPageToken(listRef, page, nextPage.nextPageToken, pageTokenArray)
    }

    return pageTokenArray
}

export const getFileData = async (path: string) => {
    const gsReference = ref(storage, path);
        
    return await getDownloadURL(gsReference)
    .then((url) => {
        return url
    })
    .catch((err) => console.log(err));
}

export const getFileMetadata = async (path: string) => {
    const gsReference = ref(storage, path);
        
    return await getMetadata(gsReference)
    .then((meta) => {
        return meta
    })
    .catch((err) => console.log(err));
}

// ファイル削除
export const deleteFile = async (path: string) => {
    const gsReference = ref(storage, path);
    await deleteObject(gsReference).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log(error)
    });
    
}