import * as React from 'react';
import * as turf from '@turf/turf'
import { useLocation } from "react-router-dom";
import {tokenLogin} from '../../../utility/firebase';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Link, useSearchParams } from "react-router-dom";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { googleMapsLine2GeoJsonLine } from "../../../utility/turf"
import { getImpactCounter } from "../../../api/DataManage"
import { drawImpactCounter } from "./ImpactCounter"
import { useUtilityContext } from '../../../utility-provider';
import {useUserContext} from '../../../user-provider';
import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  TextField,
  InputAdornment,
} from "@mui/material";
import { setImpactData } from '../../../features/impactDataSlice';


const render = (status: Status) => {
    return <h1>{status}</h1>;
};

const redColor = "#FF0000"
const down = "<<"
const shortDown = "<"
const zero = "△"
const up = ">"
const lergeUp = ">>"
const x = 0.000005


export interface impactCounterType {
  uuid: string;
  latitude: number;
  longitude: number;
  shape: string;
  name: string;
  count_time: number;
  top_car_left: number;
  top_car_bottom: number;
  top_car_right: number;
  top_people_left: number;
  top_people_bottom: number;
  top_people_right: number;
  left_car_top: number;
  left_car_bottom: number;
  left_car_right: number;
  left_people_top: number;
  left_people_bottom: number;
  left_people_right: number;
  bottom_car_top: number;
  bottom_car_left: number;
  bottom_car_right: number;
  bottom_people_top: number;
  bottom_people_left: number;
  bottom_people_right: number;
  right_car_top: number;
  right_car_left: number;
  right_car_bottom: number;
  right_people_top: number;
  right_people_left: number;
}

export interface impactCounterInterFace {
  key: string,
  data: any,
  arrowPoly: google.maps.Polyline,
}

export interface resultProps {
    props:{
    }
}

interface multiPolyIf {
  geometry: {
    type: string,
    coordinates: Object
  }
  properties: Object | null
}

const counterTypeList = () => {
  return [
    {'name': '車+人', 'val': '1'},
    {'name': '車のみ', 'val': '0'},
    {'name': '人のみ', 'val': '2'}
  ]
}

const intersectionTypeList = () => {
  return [
    {'name': '┼', 'val': '0'},
    {'name': '━', 'val': '1'},
    {'name': '┃', 'val': '2'},
    {'name': '┤', 'val': '3'},
    {'name': '├', 'val': '4'},
    {'name': '┬', 'val': '5'},
    {'name': '┴', 'val': '6'},
    {'name': 'Ｙ', 'val': '7'},
    {'name': '人', 'val': '8'},
    {'name': '＞━', 'val': '9'},
    {'name': '━＜', 'val': '10'},
    {'name': '＝', 'val': '11'},
    {'name': '｜｜', 'val': '12'},
  ]
}

const backStyle = {
  backgroundColor: 'green',
  width: '40px',
  height: '40px',
  textAlign: 'center',
  margin: '6px',
  borderRadius: '50px',
  '& a': {
    color: 'white',
    fontSize: '1.4rem',
  }
}
  
const Map:React.FC<resultProps> = ({props}) => {
  const dispatch = useAppDispatch();

    const user = useUserContext();
    //URLパラメータ
    const [searchParams] = useSearchParams();
    const lat = searchParams.get("lat");
    const lng = searchParams.get("lng");
    const zl = searchParams.get("zl");
    React.useEffect(() => {
    }, []);
    const [map, setMap] = React.useState<google.maps.Map>()
    // マップ中央
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: 35.685338597694525,
        lng: 139.7334195133164,
    });
    const [zoom, setZoom] = React.useState(16)
    const [initMap, setInitMap] = React.useState<boolean>(false)
    const [initCenter, setInitCenter] = React.useState<boolean>(false)
    const [mapReloadButton, setMapReloadButton] = React.useState<boolean>(false)
    const [userData, setUserData] = React.useState<any>({});
    const [clickImpact, setClickImpact] = React.useState<boolean>(false)

    const [angle, setAngle] = React.useState(0)
    const [polyCenter, setPolyCenter] = React.useState<google.maps.LatLngLiteral>();
    const [polyPaths, setPolyPaths] = React.useState<any>();
    const [polyLine, setPolyLine] = React.useState<google.maps.Polyline|null>();
    // メニュー表示
    const [showDisplay, setShowDisplay] = React.useState<boolean>(false);
    // スピナー
    const utilityCtx = useUtilityContext();
    const location = useLocation();
    const [showDirection, setShowDirection] = React.useState<boolean>(true)
    // 計測時間
    const [timer, setTimer] = React.useState("")
    // 種別
    const [counterType, setCounterType] = React.useState(1)
    // 道路形状
    const [intersectionType, setIntersectionType] = React.useState(0)
    const [multiPoly, setMultiPoly] = React.useState<multiPolyIf>()
    // 
    const [mode, setMode] = React.useState<string>("")
    // impactCounterData
    const [impactCounterData, setImpactCounterData] = React.useState<any>()
    const [disabled, setDisabled] = React.useState<boolean>(true)
    const idList = useAppSelector((state) => state.impactData.list);

    React.useEffect(() => {
      if (lat && lng) {
        setCenter({
          lat: Number(lat),
          lng: Number(lng)
        })
      }
      if (zl) {
        setZoom(Number(zl))
      }
    }, [lat, lng, zl])



    React.useEffect(() => {
      if (map) {
        if (idList.multiPoly !== undefined
          && idList.latlng.lat !== 0
          && idList.latlng.lng !== 0) {
          setMultiPoly(idList.multiPoly)
          setCenter(idList.latlng)
          setPolyCenter(idList.latlng)
          map.setCenter(idList.latlng)

          const shape = idList.multiPoly
          let centerPointLat =  idList.latlng.lat
          let centerPointLng =  idList.latlng.lng
  
          const paths = [
            { lat: shape.geometry.coordinates[0][1], lng: shape.geometry.coordinates[0][0] },
            { lat: shape.geometry.coordinates[1][1], lng: shape.geometry.coordinates[1][0] }
          ];
    
          setArrow({
            lat: centerPointLat,
            lng: centerPointLng
          }, map, paths, 0, true)
        } else if (idList.multiPoly === undefined) {
          setShowDisplay(false)
          setShowDirection(false)
          setTimer("")
          setCounterType(1)
          setIntersectionType(0)
        }
        if (idList.latlng.lat !== 0 && idList.latlng.lng !== 0) {
          setCenter(idList.latlng)
          map.setCenter(idList.latlng)
        }

        let hasShowDisplay  = false
        if (Number(idList.countTime) !== 0) {
          setTimer(idList.countTime.toString())
          hasShowDisplay = true
        }
        if (Number(idList.counter_type) !== 1) {
          setCounterType(Number(idList.counter_type))
          hasShowDisplay = true
        }
        if (Number(idList.intersection_type) !== 0) {
          setIntersectionType(Number(idList.intersection_type))
          hasShowDisplay = true
        }

        if (idList.impactCounterData !== null && idList.impactCounterData !== undefined) {
          setImpactCounterData(idList.impactCounterData)
          hasShowDisplay = true
        }

        if (hasShowDisplay) {
          // メニュー表示
          setShowDisplay(true)
          // 回転メニュー非表示
          setShowDirection(false)
        }
      }
    }, [map, idList])

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
      const sync = async () => {
          await tokenLogin(window.location.href)
          .then((val) => {
              if(val === 'unprocessed'){
              // 未処理の場合はバックドロップ消さない
              return;
              }
              if(user.setUserData){
                  user.setUserData(val as string);
              }
          }).catch((e) => {
              console.log(e)
          })
          }
      sync();
      if (user.userData !== '') {
        setUserData(JSON.parse(user.userData));
      }
  }, [user]);

    /**
     * 計測位置設置
     * @param e 
     * @param m 
     */
    const onClick = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
        setCenter(e.latLng!.toJSON());
        setPolyCenter(e.latLng!.toJSON())
        m.setCenter(new google.maps.LatLng(e.latLng!.lat(), e.latLng!.lng()));

        if (polyLine) {
          polyLine.setMap(null)
        }

        let centerPointLat =  e.latLng!.lat()
        let centerPointLng =  e.latLng!.lng()
        let topPoint =  (e.latLng!.lat() - (e.latLng!.lat() * x))
        let bottomPoint =  (e.latLng!.lat() + (e.latLng!.lat() * x))
        const paths = [
          { lat: topPoint, lng: centerPointLng },
          { lat: bottomPoint, lng: centerPointLng }
        ];

        setArrow({
          lat: centerPointLat,
          lng: centerPointLng
        }, m, paths, 0, false)
        // map保存
        setMap(m)
        // メニュー表示
        setShowDisplay(true)
        // 回転メニュー表示
        setShowDirection(true)
    };

    /**
     * 影響線描画
     * @param latLng 
     * @param map 
     * @param paths 
     * @param rotate 
     */
    const setArrow = (latLng:any, map: any, paths: any, rotate: any, redo: any) => {
      if (polyLine) {
        if (redo === true) {
          const tmpPaths1:any = [];
          const tmpPaths2:any = [];
          const tmpPath = polyLine.getPath()
          tmpPath.forEach((val: any) => {
            tmpPaths1.push({
              lat: val.lat(),
              lng: val.lng(),
            })
            tmpPaths2.push([val.lat(), val.lng()])
          })
          var feature = turf.points(tmpPaths2);
          const tmpCenter = turf.center(feature)
          latLng = {
            lat: tmpCenter.geometry.coordinates[0],
            lng: tmpCenter.geometry.coordinates[1],
          }
          paths = tmpPaths1
          polyLine.setMap(null)
        } else {
          if (angle !== 0) {
            rotate = angle
          }
        }
      }

      if (rotate != 0) {
        // 回転
        const r = (rotate * Math.PI) / 180
        const vx = paths[0].lat - latLng.lat
        const vy = paths[0].lng - latLng.lng
        paths[0].lat = vx * Math.cos(r) - vy * Math.sin(r)
        paths[0].lng = vx * Math.sin(r) + vy * Math.cos(r)
        paths[0].lat +=latLng.lat
        paths[0].lng +=latLng.lng

        const vxx = paths[1].lat - latLng.lat
        const vyy = paths[1].lng - latLng.lng
        paths[1].lat = vxx * Math.cos(r) - vyy * Math.sin(r)
        paths[1].lng = vxx * Math.sin(r) + vyy * Math.cos(r)
        paths[1].lat +=latLng.lat
        paths[1].lng +=latLng.lng
      }

      const line = setPath(paths)
      line.setMap(map)
      setPolyPaths(paths)
      setPolyLine(line)
    }

    const setPath = (paths: any) => {
      return new google.maps.Polyline({
        path: paths,
        geodesic: true,
        strokeColor: redColor,
        strokeOpacity: 1.0,
        strokeWeight: 4,
        draggable:true,
        icons: [
          {
            icon: { 
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeColor: redColor,
              strokeOpacity: 1.0,
              strokeWeight: 4,
              fillColor: redColor,
              fillOpacity: 1.0,
            },
            offset: "100%",
          },
        ],
      });
    }

    /**
     * 回転
     */
    const downHandler = () => {
      setAngle(angle - 45)
      setArrow(polyCenter, map, polyPaths, -45, true)
    }

    const shortDownHandler = () => {
      setAngle(angle - 10)
      setArrow(polyCenter, map, polyPaths, -10, true)
    }

    const setZero = () => {
      setArrow(polyCenter, map, polyPaths, -angle, true)
      setAngle(0)
    }

    const upHandler = () => {
      setAngle(angle + 10)
      setArrow(polyCenter, map, polyPaths, 10, true)
    }

    const lergeUpHandler = () => {
      setAngle(angle + 45)
      setArrow(polyCenter, map, polyPaths, 45, true)
    }

    const REGEX_NUMBER = /^[0-9]*$/g;
    const timerHandler =(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!e.target.value.match(REGEX_NUMBER)) {
        return
      }
      setTimer(e.target.value)
    }

    const counterTypeHandler = (event: any) => {
      setCounterType(event.target.value)
    }

    const intersectionTypeHandler = (event: any) => {
      setIntersectionType(event.target.value)
    }

    const cancelHandler = (event: any) => {
      if (!clickImpact) {
        polyLine?.setMap(null)
      } else {
        setClickImpact(false)
      }
      setMode("")
      setTimer("")
      setCounterType(1)
      setIntersectionType(0)
      setImpactCounterData(null)
      setPolyPaths(null)
      setAngle(0)
      setShowDisplay(false)
    }

    React.useEffect(() => {
      if (polyLine) {
        const multiPoly = googleMapsLine2GeoJsonLine(polyLine)
        setMultiPoly(multiPoly)
      }
    }, [polyLine])

    // 影響度データ
    const [impactCounterList, setImpactCounterList] = React.useState<impactCounterType[]>([]);
    // 影響度データ表示用
    const [icList, setIcList] = React.useState<impactCounterInterFace[]>([]);

    const callApi = async (target: string) => {
      if (target === 'getImpactCounter') {
        let latlng = {
          longitude: center.lng,
          latitude: center.lat,
        }
        if (idList.latlng && idList.latlng.lng !== 0 && idList.latlng.lat !== 0) {
          latlng = {
            longitude: idList.latlng.lng,
            latitude: idList.latlng.lat,
          }
        }
        return await getImpactCounter({
          mode: "getImpactCounter",
          longitude: latlng.longitude,
          latitude: latlng.latitude,
          uuid: "",
          distance: 1,
        })  
      }
    }

    /**
     * 影響度処理
     */
    React.useEffect(() => {
      if (map && impactCounterList && impactCounterList.length !== 0) {
          const list = drawImpactCounter(
            impactCounterList,
            map,
            icList,
            setIcList,
          )
          setIcList(list);
      } else {
          icList?.forEach((item) => {
            item.arrowPoly.setMap(null);
          })
      }
    }, [impactCounterList]);


    const setClickEvent = () => {
      icList.map((ic) => {
        // 一度クリア
        google.maps.event.clearListeners(ic.arrowPoly, "click")
        // 再設定
        ic.arrowPoly.addListener('click', (e: google.maps.MapMouseEvent) => onClickImpcatCounter(
          e,
          ic.data,
          ic,
        ));
      })
    }

    /**
     * 影響度clickイベント
     * @param e 
     * @param exSej 
     */
    const onClickImpcatCounter = (e: google.maps.MapMouseEvent, icData: any, ic: any) => {
      if (map) {
        setClickImpact(true)
        const latlon = {
          lat: icData.centerlat,
          lng: icData.centerlon,
        }
        setCenter(latlon);
        setPolyCenter(latlon)
        map.setCenter(latlon);

        setMode('clicImpactCounter')
        ic.arrowPoly.setMap(null)

        const shape = JSON.parse(icData.shape)

        const paths = [
          { lat: shape.geometry.coordinates[0][1], lng: shape.geometry.coordinates[0][0] },
          { lat: shape.geometry.coordinates[1][1], lng: shape.geometry.coordinates[1][0] }
        ];
  
        const line = setPath(paths)
        line.setMap(map)
        setPolyPaths(paths)
        setPolyLine(line)

        // カウンターリセット
        icData.top_car_left = 0
        icData.top_car_bottom = 0
        icData.top_car_right = 0
        icData.top_people_left = 0
        icData.top_people_bottom = 0
        icData.top_people_right = 0
        icData.left_car_top = 0
        icData.left_car_bottom = 0
        icData.left_car_right = 0
        icData.left_people_top = 0
        icData.left_people_bottom = 0
        icData.left_people_right = 0
        icData.bottom_car_top = 0
        icData.bottom_car_left = 0
        icData.bottom_car_right = 0
        icData.bottom_people_top = 0
        icData.bottom_people_left = 0
        icData.bottom_people_right = 0
        icData.right_car_top = 0
        icData.right_car_left = 0
        icData.right_car_bottom = 0
        icData.right_people_top = 0
        icData.right_people_left = 0
        icData.right_people_bottom = 0

        setImpactCounterData(icData)
        // メニュー表示
        setShowDisplay(true)
        // 回転メニュー非表示
        setShowDirection(false)
      }
    }

    React.useEffect(() => {
      if (initMap) {
        setMapReloadButton(true)
      }
    }, [center])

    React.useEffect(() => {
      if (initCenter && Object.keys(userData).length > 0) {
        loadMap()
      }
    }, [initCenter, userData])

    // 再読み込み
    const mapReload = () => {
      loadMap()
      setMapReloadButton(false)
    }

    const loadMap = () => {
      if (mode === '') {
        if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner();
        }
        try {
          // 影響度呼び出し
          callApi('getImpactCounter').then((res: any) => {
            setImpactCounterList(res)
          }).catch((e) => {
            console.log(e)
            if (!initMap) {
              setInitMap(true)
            }
            if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner();
            }
          }).finally(() => {
            if (!initMap) {
              setInitMap(true)
            }
            if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner();
            }
          })
        } catch (e) {
          if (!initMap) {
            setInitMap(true)
          }
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        }
      }
    }

    const onIdle = (m: google.maps.Map) => {
        if (!initCenter) {
          setInitCenter(true)
        }
        setCenter(m.getCenter()!.toJSON());
        // Map保存
        setMap(m)
    };

    React.useEffect(() => {
      let hasDisabled = true

      if (Number(timer) > 0) {
        hasDisabled = false
      }

      setDisabled(hasDisabled)

    }, [timer])

    const clickHandle = () => {
      if (!disabled) {
        const link = document.getElementById("impact-counter");
        if (link) {
            if (polyLine) {
              const tmpPaths1:any = [];
              const tmpPaths2:any = [];
              const tmpPath = polyLine.getPath()
              tmpPath.forEach((val: any) => {
                tmpPaths1.push({
                  lat: val.lat(),
                  lng: val.lng(),
                })
                tmpPaths2.push([val.lat(), val.lng()])
              })
              var feature = turf.points(tmpPaths2);
              const tmpCenter = turf.center(feature)
              const newlatLng = {
                lat: tmpCenter.geometry.coordinates[0],
                lng: tmpCenter.geometry.coordinates[1],
              }
              let centerPointLat =  newlatLng.lat
              let centerPointLng =  newlatLng.lng
              let topPoint =  (newlatLng.lat - (newlatLng.lat * x))
              let bottomPoint =  (newlatLng.lat + (newlatLng.lat * x))
              const paths = [
                { lat: topPoint, lng: centerPointLng },
                { lat: bottomPoint, lng: centerPointLng }
              ];
              const multiPoly = googleMapsLine2GeoJsonLine(polyLine)
              setMultiPoly(multiPoly)
      
              setArrow({
                lat: centerPointLat,
                lng: centerPointLng
              }, map, paths, 0, false)
              dispatch(setImpactData({
                ...idList,
                countTime: timer,
                counter_type: counterType,
                intersection_type: intersectionType,
                multiPoly: multiPoly,
                polyLine: polyLine,
                latlng: newlatLng,
                impactCounterData: impactCounterData,
              }))
              link.click()
            }
        }  
      }
    }

    return (
        <>
        <div style={{ width: '100%', height: "100%" }} onContextMenu={(e)=>{e.preventDefault()}}>
            <Wrapper apiKey={"AIzaSyBH0T_KDXD97Dg4ZA0lPi_WRZQ-c09HNnY"} libraries={['drawing','marker']} render={render} version={'quarterly'}>
                <Box sx={{ width: '100%', display: showDisplay ? 'none' : 'flex',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 3
                }}>
                  <Box sx={backStyle}><Link to={"/"}>≪</Link></Box>
                  <Box>
                    <Button
                      variant="contained" color="success" size="small"
                      onClick={mapReload}
                      sx={{ position: 'relative', left: '25%', top: '10px', display: mapReloadButton ? 'block' : 'none' }}
                    >再読み込み</Button>
                  </Box>
                </Box>
                <MapBase
                    style={{ width: '100%', height: showDisplay ? '50vh' : '100vh' }}
                    center={center}
                    onClick={onClick}
                    onIdle={onIdle}
                    onClickImpcatCounter={onClickImpcatCounter}
                    setClickEvent={setClickEvent}
                    zoom={zoom}
                >
                </MapBase>
                
                <Box sx={{ width: '100%', height: '40vh', display: showDisplay ? 'block' : 'none', bottom: 0, }}>
                  <MenuList sx={{
                    justifyContent: 'space-evenly',
                    display: showDirection === true ? 'flex' : 'none'
                  }}>
                    <MenuItem onClick={downHandler}>
                      <ListItemText>{down}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={shortDownHandler}>
                        <ListItemText>{shortDown}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={setZero}>
                        <ListItemText>{zero}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={upHandler}>
                        <ListItemText>{up}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={lergeUpHandler}>
                        <ListItemText>{lergeUp}</ListItemText>
                    </MenuItem>
                  </MenuList>
                  <MenuList>
                    <MenuItem sx={{ display: 'flex'}}>
                      <ListItemText>計測時間</ListItemText>
                      <TextField
                        sx={{width: '60%'}}
                        type="number"
                        onChange={(event) => timerHandler(event)}
                        value={timer}
                        InputProps={{
                          endAdornment: <InputAdornment position="start">分</InputAdornment>,
                        }}
                        size="small"
                      ></TextField>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex'}}>
                        <ListItemText>種別</ListItemText>
                        <Select
                        sx={{width: '60%'}}
                        value={counterType}
                        onChange={(event) => counterTypeHandler(event)}
                        size="small"
                      >
                        {counterTypeList().map((row: any) => (
                          <MenuItem key={row.val} value={row.val}>{row.name}</MenuItem>
                        ))}
                      </Select>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex'}}>
                        <ListItemText>道路形状</ListItemText>
                        <Select
                          sx={{width: '60%'}}
                          value={intersectionType}
                          onChange={(event) => intersectionTypeHandler(event)}
                          size="small"
                        >
                          {intersectionTypeList().map((row) => (
                            <MenuItem key={row.val} value={row.val}>{row.name}</MenuItem>
                          ))}
                        </Select>
                    </MenuItem>
                  </MenuList>
                </Box>
                <Box sx={{ width: '100%', height: '10vh', display: showDisplay ? 'block' : 'none', bottom: 0, }}>
                  <MenuList sx={{ justifyContent: 'space-evenly', display: 'flex' }}>
                    <MenuItem>
                      <Button
                          variant="contained" 
                          color="success"
                          onClick={(event) => cancelHandler(event)}
                      >キャンセル
                      </Button>
                    </MenuItem>
                    <MenuItem>
                        <Link id="impact-counter" to={"/Impact/Impact"}>
                        </Link>
                        <Button
                              variant="contained" 
                              color="success"
                              disabled={disabled}
                              onClick={clickHandle}
                          >カウンター
                        </Button>
                    </MenuItem>
                  </MenuList>
                </Box>
            </Wrapper>
        </div>
        </>
    );
        
}


interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
    onClick?: (e: google.maps.MapMouseEvent,map: google.maps.Map) => void;
    onIdle?: (map: google.maps.Map) => void;
    onClickImpcatCounter: any;
    setClickEvent: any;
    zoom: number;
  }

const MapBase: React.FC<MapProps> = ({
    style,
    children,
    onClick,
    onIdle,
    onClickImpcatCounter,
    setClickEvent,
    zoom,
    ...options
  }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
          setMap(new window.google.maps.Map(ref.current, {
            mapId:'e42bfbd48d30d1a0',
            disableDefaultUI: false,
            keyboardShortcuts: false,
            disableDoubleClickZoom: false,
            streetViewControl: false,
            zoomControl: true,
            scaleControl: true,
            clickableIcons: false,
            fullscreenControl: false,
            mapTypeControl: false,
            scrollwheel: true,
            center: options.center,
            zoom: zoom,
          }));
        }
    }, [ref, map]);

    React.useEffect(() => {
        if (map) {
          ["click", "idle"].forEach((eventName) =>
            google.maps.event.clearListeners(map, eventName)
            
          );
    
          if (onClick) {
            map.addListener("click", (e:google.maps.MapMouseEvent) => {onClick(e, map)});
          }

          if (onIdle) {
            map.addListener("idle", () => onIdle(map));
          }

          if (onClickImpcatCounter) {
            setClickEvent()
          }
        }
    }, [map, onClick, onClickImpcatCounter]);

    return (
      <>
        <div ref={ref} style={style} />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // set the map prop on the child component
            // @ts-ignore
            return React.cloneElement(child, { map });
          }
        })}
      </>
    );
};

export default Map;
