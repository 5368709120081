import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUtilityContext } from '../../utility-provider';
import { getReceptionBook } from '../../api/DataManage';

interface ModalProps {
    props: {
        modalOpen: boolean,
        setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    planAreaId: string|null,
    }
}; 

interface dataTypeList {
    id: string,
    status_task_update_date: string,
    elapsedTime: string,
    type: string,
    department: string,
    district: string,
    rfc: string,
    plan_area_id: string,
    candidateSiteName: string,
    assembly: string,
    pattern: string,
    branch: string,
    status_id: string,
    pic_type: string,
    pic_name: string,
    task_id: string,
    reception_no: string
}

//TableCellのヘッダースタイル変更
const TableCellSx = {
    backgroundColor: "#00CC5B",
    color: "#fff",
};
//TableRowのカーソルをポインターへ変更
const TableRowSx= {
    cursor: "pointer"
};
  
const ResearchBranchModal: React.FC<ModalProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();

    const [propsObj, setPropsObj] =useState<dataTypeList[]>();
  
    //画面遷移
    const handleRowClick = (branchType: string) => {
        //画面遷移
        const url = process.env.REACT_APP_LOCATION_URL + `/LongPage?planAreaId=${props.planAreaId}&branch=${branchType}`
        window.open(url, '_blank')
    };

    //モーダルクローズ
    const handleResearchBranchClose = () => {
        props.setModalOpen(false);
    };
  
    //ポータルコンポーネントからのデータセット
    useEffect(()=>{
        if (props.planAreaId) {
            getReceptionBookList()
        }
    },[props.modalOpen]);

    const getReceptionBookList = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            callApi('getReceptionBook').then((res: any) => {
            if (res && res.getReceptionBook.length > 0) {
                const tmpList: any = []
                res.getReceptionBook.map((row: any) => {
                    if (row.plan_area_id === props.planAreaId) {
                        row.reception_no = row.status_task_update_date
                        tmpList.push(row)
                    }
                })
                setPropsObj(tmpList)
            }
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
            }
        }
    }


    const callApi = async (target: string) => {
        if (target === 'getReceptionBook') {
            return await getReceptionBook({
                mode: "getReceptionBook",
                status_task_update_date: "",
                status_id: [],
                pic_type: [],
                pic_name: [],
                task_id: [],
                plan_area_id: "",
                department_code: [],
                district_code: [],
                plan_area_name: "",
                plan_area_rfc: ""
            })  
        }
    }
  
    return (
        <Box>
            {/* 調査書枝選択画面モーダル */}
            <Dialog open={props.modalOpen} onClose={handleResearchBranchClose} fullWidth>
                <DialogTitle sx={{ textAlign: "center" }}>閲覧する枝を選択してください</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={TableCellSx}>ステータス</TableCell>
                                    <TableCell sx={TableCellSx}>更新日時</TableCell>
                                    <TableCell sx={TableCellSx}>フラグ</TableCell>
                                    <TableCell sx={TableCellSx}>枝</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {typeof propsObj !== "undefined" &&
                                propsObj.map((value:any, index: any)=>(
                                    <TableRow key={index} sx={TableRowSx} onClick={()=>{handleRowClick(value.branch)}}>
                                    <TableCell>{value.status_id}</TableCell>
                                    <TableCell>{value.status_task_update_date}</TableCell>
                                    <TableCell>{value.reception_no}</TableCell>
                                    <TableCell>{value.branch}</TableCell>
                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
  
export default ResearchBranchModal;
  