import EXIF from "exif-js";

export const getGPSDateTimeStamp = (img: string) => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = img
        image.onload = function () {
            EXIF.getData(image as any, function () {
                const GPSDateStamp = EXIF.getTag(image, 'GPSDateStamp')
                const GPSTimeStamp = EXIF.getTag(image, 'GPSTimeStamp')
                let date: string = ""
                if (GPSDateStamp) {
                    const dataStamp = GPSDateStamp.split(':')
                    if (GPSTimeStamp[0] + 9 > 24) {
                        date = dataStamp[0] + '/' + dataStamp[1].toString().padStart( 2, '0') + '/' + (Number(dataStamp[2]) + 1).toString().padStart( 2, '0')
                        date += ' ' + ((GPSTimeStamp[0] + 9) - 24).toString().padStart( 2, '0') 
                            + ':' + GPSTimeStamp[1].toString().padStart( 2, '0')
                            + ':' + GPSTimeStamp[2].toString().padStart( 2, '0')
                    } else {
                        date = dataStamp[0] + '/' + dataStamp[1].toString().padStart( 2, '0') + '/' + dataStamp[2].toString().padStart( 2, '0')
                        date += ' ' + (GPSTimeStamp[0] + 9)
                            + ':' + GPSTimeStamp[1].toString().padStart( 2, '0')
                            + ':' + GPSTimeStamp[2].toString().padStart( 2, '0')
                    }
                }
    
                resolve({
                        date: date,
                });
            });
        }
    });
}
