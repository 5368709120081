import { configureStore } from '@reduxjs/toolkit';
import receptionBookReducer from '../features/receptionBookSlice';
import impactDataReducer from '../features/impactDataSlice';
import rviewStoreReducer from '../features/rviewStoreSlice';

// Redux Store
export const store = configureStore({
    reducer: {
        receptionBook: receptionBookReducer,
        impactData: impactDataReducer,
        rviewStoreData: rviewStoreReducer,
    }
});

// RootStateとdispatchの型定義
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
