export type receptionBookTypeList = {
    plan_area_id: string,
    branch: string,
    status_seq: string,
    create_date: string,
    reception_no: string,
    type: string,
    status_id: string,
    task_id: string,
    pic_type: string,
    pic_name: string,
    pic_staff_number: string,
    pic_mail_address: string,
    pic_department_code: string,
    pic_department_name: string,
    pic_job_category_code: string,
    pic_job_category_name: string,
    assembly: string,
    pattern: string,
    pattern_details: string,
    plan_area_name: string,
}

export const initReceptionBookObj: receptionBookTypeList = {
    plan_area_id: "",
    branch: "",
    status_seq: "",
    create_date: "",
    reception_no: "",
    type: "",
    status_id: "",
    task_id: "",
    pic_type: "",
    pic_name: "",
    pic_staff_number: "",
    pic_mail_address: "",
    pic_department_code: "",
    pic_department_name: "",
    pic_job_category_code: "",
    pic_job_category_name: "",
    assembly: "",
    pattern: "",
    pattern_details: "",
    plan_area_name: "",
};

export type impactDataList = {
  countTime: number,
  counter_type: number,
  intersection_type: number,
  multiPoly: any,
  polyLine: any,
  latlng: {
      lat: number,
      lng: number,
  },
  impactCounterData: any
}

export const initImpactDataObj: impactDataList = {
    countTime: 0,
    counter_type: 1,
    intersection_type: 0,
    multiPoly: undefined,
    polyLine: undefined,
    latlng: {
        lat: 0,
        lng: 0,
    },
    impactCounterData: undefined
}

export type locationLink = {
    store_code: String,
    link_no: String,
    link_plan_area_id: String,
    use_plan_area_id: String,
    store_plan_area_id: String,
    create_date: String,
    update_date: String,
}

export const initLocationLinkObj: locationLink = {
    store_code: "",
    link_no: "",
    link_plan_area_id: "",
    use_plan_area_id: "",
    store_plan_area_id: "",
    create_date: "",
    update_date: "",
}

export type existingSejStore = {
    store_code: String,
    ctycode: String,
    tenpo_name: String,
    tenpo_address: String,
    prop_site_no: String,
    step: number,
    centerlat: number,
    centerlon: number,
    geohash: String,
    openingdate: String,
    closingdate: String,
    doname: String,
    dmname: String,
    ofcname: String,
    zoname: String,
    contract_type: String,
    parkingarea: number,
    sales_forecast: number,
    salesfloor: String,
    liquorlicense: String,
    tobaccolicense: String,
    plan_area_feature: String,
    building_feature: String,
    entrance_feature: String,
    update_date: String,
    existing_best_location_flag: String,
    existing_multi_flag: String,
    sales_type: String,
    store_direction: String,
    sales: number,
    docode: String,
}

export const initExistingSejStoreObj: existingSejStore = {
    store_code: "",
    ctycode: "",
    tenpo_name: "",
    tenpo_address: "",
    prop_site_no: "",
    step: 0,
    centerlat: 0,
    centerlon: 0,
    geohash: "",
    openingdate: "",
    closingdate: "",
    doname: "",
    dmname: "",
    ofcname: "",
    zoname: "",
    contract_type: "",
    parkingarea: 0,
    sales_forecast: 0,
    salesfloor: "",
    liquorlicense: "",
    tobaccolicense: "",
    plan_area_feature: "",
    building_feature: "",
    entrance_feature: "",
    update_date: "",
    existing_best_location_flag: "",
    existing_multi_flag: "",
    sales_type: "",
    store_direction: "",
    sales: 0,
    docode: "",
}
