import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { useUtilityContext } from '../../utility-provider';
import { getReceptionBook } from '../../api/DataManage';
import { setReceptionBookList, setReceptionBook } from '../../features/receptionBookSlice';

import {
    Box,
    Select,
    MenuItem,
} from "@mui/material";


export interface selectPlanProps {
    props:{
    }
}


const SelectPlan:React.FC<selectPlanProps> = ({props}) => {
    const utilityCtx = useUtilityContext();
    const dispatch = useAppDispatch();
    const receptionBookList = useAppSelector((state) => state.receptionBook.list);
    const receptionBook = useAppSelector((state) => state.receptionBook.select);
    const [value, setValue] = React.useState<string>("")

    
    React.useEffect(() => {
        getReceptionBookList()
    }, [])

    const getReceptionBookList = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            callApi('getReceptionBook').then((res: any) => {
            if (res.getReceptionBook.length > 0) {
                const tmpIdList: any = []
                const tmpList: any = []
                res.getReceptionBook.map((row: any) => {
                    if (!tmpIdList.includes(row.plan_area_id)) {
                        tmpIdList.push(row.plan_area_id)
                        tmpList.push(row)
                    }
                })
                dispatch(setReceptionBookList(tmpList))
            }
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            }).catch((e) => {
            console.log(e)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            })
        } catch (e) {
            if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
            }
        }
    }

    const callApi = async (target: string) => {
        if (target === 'getReceptionBook') {
            return await getReceptionBook({
                mode: "getReceptionBook",
                status_task_update_date: "",
                status_id: [],
                pic_type: [],
                pic_name: [],
                task_id: [],
                plan_area_id: "",
                department_code: [],
                district_code: [],
                plan_area_name: "",
                plan_area_rfc: ""
            })  
        }
    }
    
    const selectHandler = (event: any) => {
        setValue(event.target.value)
        receptionBookList.map((row: any) => {
            if (row.plan_area_id === event.target.value) {
                dispatch(setReceptionBook(row))
            }
        })
    }

    React.useEffect(() => {
        if (receptionBook.plan_area_id !== "") {
            setValue(receptionBook.plan_area_id)
        }
    }, [receptionBook])

    return (
        <Box>
            <Box sx={{margin: '10px 10px'}}>候補地No</Box>
            <Box sx={{ width: '100%' }}>
                <Select
                    sx={{width: '100%'}}
                    value={value}
                    onChange={(event) => selectHandler(event)}
                >
                    {receptionBookList?.map((row: any) => (
                        <MenuItem
                            key={row.plan_area_id}
                            value={row.plan_area_id}>
                        {'候補地No：'+row.plan_area_id}</MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    )
}

export default SelectPlan;