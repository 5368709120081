import * as React from 'react';

import {
    Grid,
    Paper,
    FormControlLabel,
    Switch,
    Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
    border: '1px solid #000000',
    width: '8vh',
    height: '18vh',
    textAlign: 'center',
    padding: '20px 0',
    position: 'relative',
}

const controllerStyle = {
    display: 'flex',
    justifyContent: 'end',
}

const paperStyle = {
    width: '100%',
    position: 'absolute',
    margin: '0',
    padding: '20px 0',
    top: '0',
    border: 'none',
    boxShadow: 'none',
}

export interface resultProps {
    props:{
    }
  }

const defaultCountList = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
    17: 0,
    18: 0,
    19: 0,
    20: 0,
    21: 0,
    22: 0,
    23: 0,
}

const TrafficCounter:React.FC<resultProps> = ({props}) => {

    const [checked, setChecked] = React.useState(true);
    const [countList, setCountList] = React.useState(defaultCountList)
    const [counter, setCounter] = React.useState(false);

    function handleChange() {
        setChecked(!checked)
    }


    function setCount(key: number) {
        if (countList) {
            const tmp:any[] = [];
            tmp.push(countList)
            if (checked) {
                // +
                tmp[0][key] = tmp[0][key] + 1
            } else {
                // -
                if (tmp[0][key] > 0) {
                    tmp[0][key] = tmp[0][key] - 1
                }
            }
            setCounter(!counter)
            setCountList(tmp[0])
        }

        return
    }

    return (
        <>
            <Grid container sx={{padding: '20px'}}>
                <Grid item xs={10}>
                    <Link to="/">≪</Link>
                </Grid>
                <Grid item xs={2} sx={controllerStyle}>
                    <label style={{
                        textAlign: 'center',
                        padding: '10px 0'
                    }}>-</label><FormControlLabel
                        control={<Switch checked={checked} onChange={handleChange} />}
                        label="+"/>
                </Grid>
                <Grid container xs={1}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}></Grid>
                </Grid>
                <Grid container xs={3}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}>●●店来店調査</Grid>
                </Grid>
                <Grid container xs={4}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>計測時間</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>調査日</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>A</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>B</Grid>
                </Grid>
                <Grid container xs={4}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>開始時間</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>終了時間</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>時間</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>時間</Grid>
                </Grid>
                <Grid container xs={1}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}></Grid>
                </Grid>
                <Grid container xs={5}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}>通過TR</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={4}>前面</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={4}>側道A</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={4}>側道B</Grid>
                </Grid>
                <Grid container xs={2}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}>前面</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>上手</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>下手</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>からの来店</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>への退店</Grid>
                </Grid>
                <Grid container xs={2}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}>側道A</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>上手</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>下手</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>からの来店</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>への退店</Grid>
                </Grid>
                <Grid container xs={2}>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={12}>側道B</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>上手</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>下手</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>からの来店</Grid>
                    <Grid sx={{ border: '1px solid #000000'}} item xs={6}>への退店</Grid>
                </Grid>
                <Grid container xs={1}>
                    <Grid sx={{ border: '1px solid #000000', textAlign: 'center', padding: '20px 0'}} item xs={12}>車</Grid>
                    <Grid sx={{ border: '1px solid #000000', textAlign: 'center', padding: '20px 0'}} item xs={12}>人</Grid>
                </Grid>
                <Grid container xs={5}>
                    <Grid sx={style} item xs={4}><Paper sx={paperStyle} onClick={(event) => setCount(0)}>{countList[0]}</Paper></Grid>
                    <Grid sx={style} item xs={4}><Paper sx={paperStyle} onClick={(event) => setCount(1)}>{countList[1]}</Paper></Grid>
                    <Grid sx={style} item xs={4}><Paper sx={paperStyle} onClick={(event) => setCount(2)}>{countList[2]}</Paper></Grid>
                    <Grid sx={style} item xs={4}><Paper sx={paperStyle} onClick={(event) => setCount(3)}>{countList[3]}</Paper></Grid>
                    <Grid sx={style} item xs={4}><Paper sx={paperStyle} onClick={(event) => setCount(4)}>{countList[4]}</Paper></Grid>
                    <Grid sx={style} item xs={4}><Paper sx={paperStyle} onClick={(event) => setCount(5)}>{countList[5]}</Paper></Grid></Grid><Grid container xs={2}>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(6)}>{countList[6]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(7)}>{countList[7]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(8)}>{countList[8]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(9)}>{countList[9]}</Paper></Grid></Grid><Grid container xs={2}>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(10)}>{countList[10]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(11)}>{countList[11]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(12)}>{countList[12]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(13)}>{countList[13]}</Paper></Grid></Grid><Grid container xs={2}>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(14)}>{countList[14]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(15)}>{countList[15]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(16)}>{countList[16]}</Paper></Grid>
                    <Grid sx={style} item xs={6}><Paper sx={paperStyle} onClick={(event) => setCount(17)}>{countList[17]}</Paper></Grid>
                </Grid>
            </Grid>
        </>
    );
        
}


export default TrafficCounter;