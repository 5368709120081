export const zIndexOrder = {
    kouhochiPolygon: 100,
    buildingPolygon: 101,
    buildingEntrance: 102,
    // 所有者賃貸人
    ownerLessor: 103,
    // 編集モード時は一律300
    zoneEditPolygon: 300,
    // ピンメモ系
    pinMemoPin: 201,
    pinMemoPolygon: 200,
    pinMemoLine: 202,
    pinMemoArrow: 203,
    pinMemoMarker: 299,
    // ×印は一番上
    pinMemoCrossIcon: 900,
    marketArea: 10,
    // 店舗アイコン
    existingSejStoreIcon: 410,
    conflictStoreIcon: 402,
    closedSejStoreIcon: 401,
    // 店舗バッジ
    badge: 450,
    // 出店戦略
    zoneStrategy: 440,
    background: 50,
    // zone
    zonePolygon: 60,
    // 影響度
    impactArrow: 70,
    // 所有者賃貸人位置変更時
    editOwnerLessor: 1001,
  };