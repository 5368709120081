import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";

function App() {

  return (
    <div style={{ width: "375px" }} className="App">
      <br /><br /><br />
      <Link to={"/Impact"} state={{
        intersectionName: "",
        countTime: 0,
        counter_type: 0,
        intersection_type: 0,
        stateMultiPoly: null,
        stateLatlng: null,
        stateImpactCounterData: null
      }}>影響度</Link>
      <br /><br /><br />
      <Link to={"/TrafficVolume"}>通行量</Link><br /><br /><br />
      <Link to={"/Photo"}>写真アップロード</Link><br /><br /><br />
      <Link to={"/TrafficCounter"}>TrafficCounter</Link>
    </div>
  );
}

export default App;
