import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { useUtilityContext } from '../../../utility-provider';
import SelectPlan from '../SelectPlan';

import {
    Button,
    Box,
} from "@mui/material";
import { Link } from "react-router-dom";

export interface trafficVolumeIndexProps {
    props:{
    }
}


const TrafficVolumeIndex:React.FC<trafficVolumeIndexProps> = ({props}) => {
    const receptionBook = useAppSelector((state) => state.receptionBook.select);
    const [disabled, setDisabled] = React.useState<boolean>(true)

    React.useEffect(() => {
        setDisabled(true)
        if (receptionBook.plan_area_id !== "") {
            setDisabled(false)
        }
    }, [receptionBook])

    
    const clickHandle = () => {
        if (!disabled) {
          const link = document.getElementById("trafficVolume");
          if (link) {
              link.click()
          }  
        }
    }

    return (
        <Box style={{ width: "100%", margin: 0, padding: 0, position: 'relative' }}>
            <Box sx={{margin: '0 10px'}}>
                <Link to="/">≪</Link>
            </Box>
            <Box sx={{margin: '10px'}}>
                <SelectPlan props={{ }} />
            </Box>
            <Box sx={{margin: '20px 10px'}}>
                <Link
                    id="trafficVolume"
                    to={"/TrafficVolume/select?planAreaId="+receptionBook.plan_area_id+"&referrer=" }>
                </Link>
                <Button
                    variant="contained" 
                    color="success"
                    disabled={disabled}
                    onClick={clickHandle}
                >計測</Button>
            </Box>
        </Box>
    )
}

export default TrafficVolumeIndex;