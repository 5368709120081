import { createSlice } from '@reduxjs/toolkit';
import { initImpactDataObj } from '../app/type';

//Sliceの作成
export const impactDataSlice = createSlice({
    name: 'impact',
    initialState: {
        list: initImpactDataObj,
    },

    //toolKitを利用した値変更処理
    reducers: {
        setImpactData: (state, action) => {
            state.list = action.payload;
        },
    }
});

export const {
    setImpactData,
} = impactDataSlice.actions;
export default impactDataSlice.reducer;