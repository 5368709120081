import React from 'react';
import './App.css';
import { createRoot } from "react-dom/client";
import { Route, Routes, BrowserRouter } from "react-router-dom";

// top
import App from './App';
// components
import Map from './components/data/Impact/Map';
import Impact from './components/data/Impact/Impact';
import TrafficVolumeIndex from './components/data/TrafficVolume/TrafficVolumeIndex';
import TrafficVolume from './components/data/TrafficVolume/TrafficVolume';
import TrafficVolumeRegist from './components/data/TrafficVolume/TrafficVolumeRegist';
import TrafficCounter from './components/data/TrafficCounter/TrafficCounter';
import { UtilityProvider } from './utility-provider';
import PhotoIndex from './components/data/Photo/PhotoIndex';
import Upload from './components/data/Photo/Upload';
import Login from './Login'
import { Provider } from 'react-redux';
import { store } from './app/store';
import { UserProvider } from './user-provider';
import Planareamenu from './components/data/Planareamenu/Planareamenu';
import StoreMenu from './components/data/StoreMenu/StoreMenu';

const rootElement = document.getElementById("root");

// ざっくりセキュリティ対応
// const params = window.location.search.replace('?','')
// if(params !== 'Ju6L2NuibWsVztS4zfnKLc7psYir7swmnDPJLkSJH88nm2giKdwbEWeNzb7Wwrizzd5kiUZvJcmbc775'){
//   window.location.href = 'https://www.google.co.jp/';
// }

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={ store }>
      <UserProvider>
        <Login url={window.location.href} />
        <UtilityProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/Impact" element={<Map props={{}} />} />
              <Route path="/Impact/Impact" element={<Impact props={{}} />} />
              <Route path="/Photo" element={<PhotoIndex props={{}} />} />
              <Route path="/Photo/upload" element={<Upload props={{}} />} />
              <Route path="/TrafficVolume" element={<TrafficVolumeIndex props={{}} />} />
              <Route path="/TrafficVolume/select" element={<TrafficVolume props={{}} />} />
              <Route path="/TrafficVolume/regist" element={<TrafficVolumeRegist props={{}} />} />
              <Route path="/TrafficCounter" element={<TrafficCounter props={{}} />} />
              {/* Rviewからの遷移画面 */}
              <Route path="/Planareamenu" element={<Planareamenu props={{}} />} />
              {/* Rviewからの遷移画面 既存店 */}
              <Route path="/StoreMenu" element={<StoreMenu props={{}} />} />
            </Routes>
          </BrowserRouter>
        </UtilityProvider>
      </UserProvider>
    </Provider>
  );
}