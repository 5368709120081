import * as React from 'react';

import { GeoJsonLine } from '../../../utility/turf';
import { zIndexOrder } from '../../../utility/config';

export const drawImpactCounter = (
    impactCounterList: any,
    map: google.maps.Map,
    icList: any,
    setIcList: any,
) => {
    const tmpMarker:any[] = [];
    impactCounterList.forEach((data: any, index: any) => {
        try {
          const polyLine = JSON.parse(data.shape) as GeoJsonLine
          const arrowPoly = new google.maps.Polyline({
            path: polyLine.geometry.coordinates.map((val: any) => {
              return {lat: val[1], lng: val[0]}
            }),
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 4,
            draggable:false,
            clickable: true,
            icons: [
              {
                icon: { 
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  strokeColor: "#FF0000",
                  strokeOpacity: 1.0,
                  strokeWeight: 4,
                  fillColor: "#FF0000",
                  fillOpacity: 1.0,
                },
                offset: "100%",
              },
            ],
            zIndex: zIndexOrder.impactArrow
          })

          arrowPoly.setMap(map)

          tmpMarker.push({
            key: data.uuid,
            data: data,
            arrowPoly: arrowPoly,
          })

        } catch (error) {
            console.log("------------------------")
            console.log("表示エラー")
            console.log(error)
            console.log(data)
            console.log("------------------------")
        }
    })

    // 前回表示分のリスト取得
    const  diffBefore: any[] = []
    icList?.forEach((item: any) => {
        diffBefore.push(item.data)
    })
    // 前回表示分のリストとの差分取得
    const diff = diffBefore.filter(x => !impactCounterList.includes(x))

    setIcList(tmpMarker);

    // 前回表示分の差分をMAPから表示削除
    if (diff.length > 0) {
        diffClear(diff, icList)
    }

    return tmpMarker
}

/**
 * 前回表示分のmarker、polygonの差分を非表示にする
 * @param diffList 
 * @param list 
 */
const diffClear = (diffList: any, list: any) => {
  list?.forEach(function(item: any){
      diffList?.forEach(function(diffItem: any){
      if (item.key === diffItem.uuid) {
          item.arrowPoly.setMap(null);
      }
      })
  });
}

export interface impactCounterProps {
    props:{
    }
  }

const ImpactCounter:React.FC<impactCounterProps> = ({props}) => {

    return (
        <></>
    );
        
}


export default ImpactCounter;
