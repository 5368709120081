import { postRequest } from "./AxiosAction";
import { getApiParamArray, getApiParam } from "./Params";


// graphqlの呼び出し
export const graphqlRequest = async <T>(params: any) => {
  const graphql_api_url: string = process.env.REACT_APP_API_SERVER_URL! + "/graphql";
  let res: T;
  try {
    res = await postRequest(graphql_api_url, getApiParamArray(params)) as T;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
  return res;
};

export const apiRequest = async (params: any) => {
  const graphql_api_url: string = process.env.REACT_APP_API_SERVER_URL! + params.endPoint;
  let res: any = {};
  try {
    res = await postRequest(graphql_api_url, getApiParam(params));
  } catch (error) {
    console.log("error", error);
    throw error;
  }
  return res;
};