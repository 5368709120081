import { createSlice } from '@reduxjs/toolkit';
import { initLocationLinkObj, initExistingSejStoreObj } from '../app/type';

//Sliceの作成
export const rviewStoreSlice = createSlice({
    name: 'conflict',
    initialState: {
        storeCode: "",
        locationLink: [initLocationLinkObj],
        existingSejStore: initExistingSejStoreObj,
    },

    //toolKitを利用した値変更処理
    reducers: {
        setStoreCode: (state, action) => {
            state.storeCode = action.payload;
        },
        setLocationLinkObj: (state, action) => {
            state.locationLink = action.payload;
        },
        setExistingSejStoreObj: (state, action) => {
            state.existingSejStore = action.payload;
        },
    }
});

export const {
    setStoreCode,
    setLocationLinkObj,
    setExistingSejStoreObj,
} = rviewStoreSlice.actions;
export default rviewStoreSlice.reducer;