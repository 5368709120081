import React from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface confirmDialogProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    onCancel:any,
    onExecution:any,
    onCancelRegist:any,
    mode:string,
    body:string,
    confirmCancel:string,
    confirmOk:string,
    confirmCancelRegist:string,
  }
}

const ConfirmDialog:React.FC<confirmDialogProps> = ({props}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    props.onCancel()
  };

  const handleOk = () => {
    props.onExecution()
  };

  const handleCloseRegist = () => {
    props.onCancelRegist()
  };

  React.useEffect(() =>{
    setOpen(props.open);
  },[props.open])

    return (
      <div>
      <Dialog
        open={open}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{ margin: ' 20px' }}>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{props.body}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-around' }}>
                <Button variant="outlined" color="error" size="small" sx={{ display: props.confirmCancel === '' ? 'none' : 'block' }} onClick={handleClose}>{props.confirmCancel}</Button>
                <Button variant="contained" color="success" size="small" sx={{ display: props.confirmOk === '' ? 'none' : 'block' }} onClick={handleOk}>{props.confirmOk}</Button>
                <Button variant="contained" color="error" size="small" sx={{ display: props.confirmCancelRegist === '' ? 'none' : 'block' }} onClick={handleCloseRegist}>{props.confirmCancelRegist}</Button>
            </DialogActions>
        </Box>
      </Dialog>
      </div>
    );
  
}

export default ConfirmDialog;


