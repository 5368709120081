// OFCダッシュボードの画面に応じたAPIのパラメータを返す
export const getApiParamArray = (params: any) => {
  return {
    query:
      params.query + ' { ' + params.params.map((row: any) => { return setQuery(row) }) + ' } '
  }
}

export const getApiParam = (params: any) => {
  return setQuery(params)
}

export const setQuery = (params: any) => {
    switch (params.mode) {
      case "existingSejStoreSearchByDistance":
        return 'existingSejStoreSearchByDistance(longitude:' + params.longitude +
            ', latitude:' + params.latitude +
            ', distance:' + params.distance +
            ') {' +
              'store_code ' +
              'ctycode ' +
              'tenpo_name ' +
              'tenpo_address ' +
              'prop_site_no ' +
              'step ' +
              'centerlat ' +
              'centerlon ' +
              'geohash ' +
              'openingdate ' +
              'closingdate ' +
              'doname ' +
              'dmname ' +
              'ofcname ' +
              'zoname ' +
              'contract_type ' +
              'parkingarea ' +
              'sales_forecast ' +
              'salesfloor ' +
              'liquorlicense ' +
              'tobaccolicense ' + 
              'plan_area_feature ' +
              'building_feature ' + 
              'entrance_feature ' +
              'existing_best_location_flag ' +
              'store_direction ' +
              'sales_type ' +
              'existing_multi_flag ' +
            ' }'
      case "planAreaSearchById":
        return 'planAreaSearchById(id: "' + params.id + '"' +
              ', manageCitiesFlg: ' + params.manageCitiesFlg +
              ', manageCities: "' + params.manageCities + '"' +
              ', manageStep:  "' + params.manageStep + '"' +
              ', managePlansOutsideCities:  "' + params.managePlansOutsideCities + '"' +
              ') { ' +
              ' id ' +
              ' uuid ' +
              ' centerlat ' +
              ' centerlon  ' +
              ' entrance_feature ' +
              ' plan_area_feature ' +
              ' building_feature ' +
              ' citycode ' +
              ' display_flag ' +
              ' deletable_flag ' +
              ' request_flag ' +
              ' edit_flag ' +
              ' corporate_flag ' +
              ' plan_area_name ' +
              ' step ' +
              ' plan_area_address ' +
              ' plan_area_zipcode  ' +
              ' plan_area_townname ' +
              ' plan_area_townname_serial_number ' +
              ' zone_id ' +
              ' plan_area_area_size ' +
              ' building_area_size ' +
              ' plan_area_current_state ' +
              ' plan_area_owner_ids ' +
              ' plan_area_amount_sales ' +
              ' latlon_jpn ' +
              ' do_code ' +
              ' dm_confirm ' +
              ' rm_confirm ' +
              ' existing_best_location_flag ' +
              ' existing_multi_flag ' +
              ' evaluation_score ' +
              ' visit_activity ' +
              ' current_situation1 ' +
              ' current_situation2 ' +
              ' minor_classification1 ' +
              ' minor_classification2 ' +
              ' tenant_name1 ' +
              ' tenant_name2 ' +
              ' branch_name1 ' +
              ' branch_name2 ' +
              ' remarks ' +
              ' pending_flag ' +
              ' create_date ' +
              ' update_date ' +
              ' store_direction ' +
              ' giving_up_reason ' +
              ' }'
      case "getImpactCounter":
        return 'getImpactCounter(' +
              ' longitude: ' + params.longitude +
              ', latitude: ' + params.latitude +
              ', uuid: "' + params.uuid + '"' +
              ', distance:  ' + params.distance +
              ') { ' +
              ' uuid ' +
              ' seq ' +
              ' centerlat ' +
              ' centerlon ' +
              ' shape ' +
              ' name ' +
              ' count_date ' +
              ' count_time ' +
              ' counter_type ' +
              ' intersection_type ' +
              ' top_car_left ' +
              ' top_car_bottom ' +
              ' top_car_right ' +
              ' top_people_left ' +
              ' top_people_bottom ' +
              ' top_people_right ' +
              ' left_car_top ' +
              ' left_car_bottom ' +
              ' left_car_right ' +
              ' left_people_top ' +
              ' left_people_bottom ' +
              ' left_people_right ' +
              ' bottom_car_top ' +
              ' bottom_car_left ' +
              ' bottom_car_right ' +
              ' bottom_people_top ' +
              ' bottom_people_left ' +
              ' bottom_people_right ' +
              ' right_car_top ' +
              ' right_car_left ' +
              ' right_car_bottom ' +
              ' right_people_top ' +
              ' right_people_left ' +
              ' }'
      case "getReceptionBookById":
        return 'getReceptionBookById(' +
              ' plan_area_id: "' + params.plan_area_id +'"'+
              ') { ' +
              ' plan_area_id ' +
              ' branch ' +
              ' status_seq ' +
              ' create_date ' +
              ' reception_no ' +
              ' type ' +
              ' status_id ' +
              ' task_id ' +
              ' pic_type ' +
              ' pic_name ' +
              ' pic_staff_number ' +
              ' pic_mail_address ' +
              ' pic_department_code ' +
              ' pic_department_name ' +
              ' pic_job_category_code ' +
              ' pic_job_category_name ' +
              ' assembly ' +
              ' pattern ' +
              ' pattern_details ' +
              ' plan_area_name ' +
              ' }'
      case "getReceptionBook":
        return 'getReceptionBook(' +
                '   status_task_update_date: "' + params.status_task_update_date + '"' +
                ' , status_id: [' + params.status_id + ']' +
                ' , pic_type: [' + params.pic_type + ']' +
                ' , pic_name: [' + params.pic_name + ']' +
                ' , task_id: [' + params.task_id + ']' +
                ' , plan_area_id: "' + params.plan_area_id + '"' +
                ' , department_code: [' + params.department_code + ']' +
                ' , district_code: [' + params.district_code + ']' +
                ' , plan_area_name: "' + params.plan_area_name + '"' +
                ' , plan_area_rfc: "' + params.plan_area_rfc + '"' +
                ') { ' +
                'plan_area_id ' +
                'branch ' +
                'status_seq ' +
                'create_date ' +
                'reception_no ' +
                'type ' +
                'status_id ' +
                'task_id ' +
                'pic_type ' +
                'pic_name ' +
                'pic_staff_number ' +
                'pic_mail_address ' +
                'pic_department_code ' +
                'pic_department_name ' +
                'pic_job_category_code ' +
                'pic_job_category_name ' +
                'assembly ' +
                'pattern ' +
                'pattern_details ' +
                'plan_area_name ' +
                'status_task_update_date ' +
              ' }'
      case "getLocationLink":
        return 'getLocationLink(storeCode: "' + params.storeCode + '"' +
              ') { ' +
              'store_code ' +
              'link_no ' +
              'link_plan_area_id ' +
              'use_plan_area_id ' +
              'store_plan_area_id ' +
              'create_date ' +
              'update_date ' +
              ' }'
      case "getExistingSejStore":
        return 'getExistingSejStore(storeCode: "' + params.storeCode + '"' +
              ') { ' +
              'store_code ' +
              'ctycode ' +
              'tenpo_name ' +
              'tenpo_address ' +
              'prop_site_no ' +
              'step ' +
              'centerlat ' +
              'centerlon ' +
              'geohash ' +
              'openingdate ' +
              'closingdate ' +
              'doname ' +
              'dmname ' +
              'ofcname ' +
              'zoname ' +
              'contract_type ' +
              'parkingarea ' +
              'sales_forecast ' +
              'salesfloor ' +
              'liquorlicense ' +
              'tobaccolicense ' +
              'plan_area_feature ' +
              'building_feature ' +
              'entrance_feature ' +
              'update_date ' +
              'existing_best_location_flag ' +
              'existing_multi_flag ' +
              'sales_type ' +
              'store_direction ' +
              'sales ' +
              'docode ' +
              ' }'
      case "postRequest":
          return JSON.stringify(params.query);
      default:
        return "";
    }
  };
  